
import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from 'vue';
import { Rules } from 'async-validator';
import { ElMessage } from 'element-plus';

import ImageUploader from '@/components/ImageUploader.vue';
import { ResponseError, updateBlockAdvertisement } from '@/services/api';
import { PartialBlock, PartialBlockAdvertisement } from '@/interfaces/Block';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialBlockAdvertisement = {
  image: '',
  imageLink: '',
  mobileImage: '',
  mobileImageLink: '',
  link: ''
};

const rules: Rules = {
  image: [
    {
      required: true
    }
  ],
  mobileImage: [
    {
      required: true
    }
  ],
  link: [
    {
      type: 'url'
    }
  ]
};

export default defineComponent({
  props: {
    block: {
      type: Object as PropType<PartialBlock>,
      required: true
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { block } = toRefs(props);
    const { t } = useI18n();

    const formValues = ref({
      ...DEFAULT_FORM_VALUES,
      ...(block.value as PartialBlock).advertisement
    });
    watch(block, (block: PartialBlock) => {
      formValues.value = block.advertisement;
    });

    const isLoading = ref(false);
    const update = async() => {
      isLoading.value = true;

      try {
        await updateBlockAdvertisement({
          blockId: `${(block.value as PartialBlock).id}`,
          data: formValues.value
        });
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
        isLoading.value = false;
        return;
      }

      ElMessage.success('success!');
      isLoading.value = false;
    };

    return {
      rules,
      formValues,
      isLoading,
      t,
      update
    };
  }
});
