
import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
  nextTick
} from 'vue';
import { ElMessage, ElTable } from 'element-plus';

import {
  ResponseError,
  getBlockVideos,
  MAX_PER_PAGE
} from '@/services/api';
import {
  useSaveBlockVideos
} from '@/composables/api';
import { isNumber } from '@/utils';
import { BlockVideo } from '@/interfaces/Block';
import { DEFAULT_SORT } from '../constants';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Is Free',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Yes',
        value: '1'
      },
      {
        label: 'No',
        value: '0'
      }
    ]
  }
];

export default defineComponent({
  components: {
    Filter
  },
  props: {
    blockId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { blockId } = toRefs(props);
    const videos = ref([]);
    const sortOrder = ref();
    const isFree = ref();
    const { t } = useI18n();

    const {
      isLoading,
      mutate
    } = useSaveBlockVideos();

    const tableRef = ref<InstanceType<typeof ElTable>>(null);

    const multipleSelections = ref<BlockVideo[]>([]);
    watch(multipleSelections, (selections) => {
      videos.value.forEach(video => {
        const target = selections.find(({ id }) => id === video.id);
        if (target && !isNumber(target.sort)) target.sort = DEFAULT_SORT;
      });
    });

    const handleSelectionChange = (selections: BlockVideo[]) => {
      multipleSelections.value = selections;
    };

    const toggleSelections = async() => {
      await nextTick();
      videos.value.forEach((video) => {
        if (isNumber(video.sort)) {
          tableRef.value.toggleRowSelection(video, true);
        }
      });
    };

    watch(videos, async() => {
      toggleSelections();
    }, { deep: true });

    const fetchBlockVideos = async() => {
      const { data } = await getBlockVideos({
        blockId: blockId.value,
        query: {
          sort: sortOrder.value,
          perPage: MAX_PER_PAGE,
          isFree: isFree.value
        }
      });
      videos.value = data.map(({ sort, ...resVideo }) => ({
        ...resVideo,
        ...(isNumber(sort) && { sort })
      }));

      toggleSelections();
    };

    onMounted(() => {
      fetchBlockVideos();
    });

    const handleSortChange = ({ order }) => {
      sortOrder.value = order === 'ascending' ? 'asc' : 'desc';

      fetchBlockVideos();
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      isFree.value = event[0];
      fetchBlockVideos();
    };

    const saveVideos = () => {
      mutate(
        {
          blockId: blockId.value,
          data: {
            videoIds: multipleSelections.value.map(({ id, sort }) => ({ id, sort }))
          }
        },
        {
          onSuccess() {
            ElMessage.success('success!');

            fetchBlockVideos();
          },
          onError(error: ResponseError) {
            ElMessage.error(error.response?.data.message);
          }
        }
      );
    };

    return {
      videos,
      tableRef,
      isLoading,
      handleSelectionChange,
      handleSortChange,
      saveVideos,
      FILTER_OPTIONS,
      t,
      handleFilterChange
    };
  }
});
